<template>
  <div class="bx-content bx-vod-episodes bx-typo--vod-epg bx-typo--vod-episodes">
    <h3>Die neuesten Episoden von "{{ seriesTitle }}"</h3>
    <form class="bx-form">
      <div class="bx-form__select-wrapper">
        <select v-model="selectedSeason"
                class="bx-form__select"
                :disabled="isLoading || numberOfSeasons === 1"
                @change="changeSeason()">
          <option v-for="i in numberOfSeasons"
                  :key="i"
                  :value="i">
            Staffel {{ i }}
            <span v-if="numberOfSeasons === 1">
              von {{ numberOfSeasons }}
            </span>
          </option>
        </select>
        <Icon v-if="numberOfSeasons > 1"
              name="arrow_toggle" />
      </div>
    </form>
    <ShowMore :aria-controls-id="`episodes-list-${listOrderPosition}`"
              aria-content-label="Folgen"
              content-mode="list"
              :data-loader="isLoading"
              data-loader-style="container"
              :max-list-items="3">
      <ol>
        <li v-for="episode in currentSeason.episodes"
            :key="episode.number">
          <div>
            <figure>
              <img :src="getImageSrc(episode.stillPath)"
                   :alt="episode.title">
            </figure>
            <div>
              <div>
                {{ episode.number }}. {{ episode.title }}
              </div>
              <div>
                <span>Staffel {{ currentSeason.seasonNumber }} | Folge {{ episode.number }}/{{ currentSeason.episodes.length }}</span>
                <span v-if="episode.runtime"> | {{ episode.runtime }} Minuten</span>
              </div>
            </div>
          </div>
          <div v-if="episode.description"
               class="bx-typo--content-continuous-text">
            <p>{{ episode.description }}</p>
          </div>
        </li>
      </ol>
    </ShowMore>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import ShowMore from '../shared/ShowMore.vue'
import Icon from '../shared/Icon.vue'

export default {
  components: {
    ShowMore,
    Icon
  },
  props: {
    seriesCapiId: {
      type: String,
      required: true
    },
    seriesTitle: {
      type: String,
      required: true
    },
    seasonNumber: {
      type: Number,
      required: true
    },
    numberOfSeasons: {
      type: Number,
      required: true
    },
    listOrderPosition: {
      type: Number,
      default: null
    },
    episodes: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      isLoading: null,
      selectedSeason: this.seasonNumber, // season picker
      currentSeason: { // defaults on initial load
        seasonNumber: this.seasonNumber,
        episodes: this.episodes
      }
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig'])
  },
  methods: {
    async changeSeason () {
      this.isLoading = true
      const url = `${this.rsConfig.vodServiceUrl}/${this.seriesCapiId}/season/${this.selectedSeason}/with-episodes`
      const { data: { episodes } } = await axios.get(url) || {}

      if (episodes?.length) {
        this.currentSeason = {
          seasonNumber: this.selectedSeason,
          episodes
        }
      }
      this.isLoading = null
    },
    getImageSrc (stillPath) {
      return stillPath ? `https://image.tmdb.org/t/p/w200${stillPath}` : '/images/tvmovie/fallback-episodes-teaser.webp'
    }
  }
}
</script>
