<template>
  <div v-if="hasNonEmptyTabs"
       class="bx-content bx-tabbed-info bx-typo--tabbed-info">
    <h3 class="bx-tabbed-info__heading">
      {{ headline }}
    </h3>
    <TabContainer>
      <Tab v-for="(tab, tabIndex) in filteredTabs"
           :key="tab.title"
           :title="tab.title">
        <div :data-tc="`tabbed-info-${tabIndex}`">
          <ShowMore :max-list-items="8"
                    content-mode="list"
                    :aria-controls-id="`tabbed-info-${listOrderPosition}-${tabIndex}`"
                    :aria-content-label="tab.title">
            <ul class="bx-tabbed-info__wrapper">
              <li v-for="(item, itemIndex) in tab.items"
                  :key="itemIndex"
                  :data-tc="`tabbed-info-${tabIndex}-item-${itemIndex}`">
                <span>
                  {{ item.key }}
                </span>
                <span>
                  {{ item.value }}
                </span>
              </li>
            </ul>
          </ShowMore>
        </div>
      </Tab>
    </TabContainer>
  </div>
</template>

<script>
import { TabContainer, Tab } from '../shared/tabs'
import ShowMore from '../shared/ShowMore.vue'

export default {
  name: 'ParagraphTypeVodStreamingOptions',
  components: {
    TabContainer,
    Tab,
    ShowMore
  },
  props: {
    headline: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: () => []
    },
    listOrderPosition: {
      type: Number,
      default: 0
    }
  },
  computed: {
    filteredTabs () {
      return this.tabs.filter(tab => tab.items?.length > 0)
    },
    hasNonEmptyTabs () {
      return this.filteredTabs.length > 0
    }
  }
}
</script>
