<template>
  <div class="bx-show-more bx-typo--show-more"
       :class="{
         'bx-js-show-more-hide': !showMore && !contentSmallerThanInitialSize,
         [`bx-show-more--${contentMode}`]: true
       }">
    <div :id="ariaControlsId"
         ref="content"
         :class="{
           'bx-line-clamp': textMode && !showMore,
           [`bx-line-clamp--${maxLines}`]: textMode && !showMore
         }">
      <slot />
    </div>
    <button v-if="!contentSmallerThanInitialSize"
            tabindex="0"
            role="button"
            class="bx-show-more__toggle-button"
            :aria-controls="ariaControlsId"
            :aria-label="ariaLabel"
            :aria-expanded="ariaExpanded"
            @click="showMore = !showMore">
      <span>{{ ariaLabel }}</span>
      <Icon name="arrow_toggle" />
    </button>
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {
  name: 'ShowMore',
  components: { Icon },
  props: {
    ariaContentLabel: {
      type: String,
      default: ''
    },
    // we need a unique id for the aria-controls attribute
    // which has to be defined from the parent component
    // to ensure that the id is unique and working in ssr and csr
    ariaControlsId: {
      type: String,
      required: true
    },
    maxLines: {
      type: Number,
      default: 3,
      validator: (value) => value > 0 && value <= 8
    },
    maxListItems: {
      type: Number,
      default: 3
    },
    contentMode: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'list'].includes(value)
    }
  },
  data () {
    return {
      showMore: false,
      contentSmallerThanInitialSize: false
    }
  },
  computed: {
    ariaLabel () {
      return this.showMore ? `weniger ${this.ariaContentLabel} anzeigen` : `mehr ${this.ariaContentLabel} anzeigen`
    },
    ariaExpanded () {
      return this.showMore ? 'true' : 'false'
    },
    textMode () {
      return this.contentMode === 'text'
    },
    listMode () {
      return this.contentMode === 'list'
    }
  },
  mounted () {
    if (this.listMode) {
      this.showInitialListItems()
    } else if (this.textMode) {
      this.checkInitialTextOverflow()
    }
  },
  methods: {
    // check if the content is smaller than the initial size
    // and only display the show more button if necessary
    checkInitialTextOverflow () {
      const textContent = this.$refs.content
      textContent.classList.remove('bx-line-clamp', `bx-line-clamp--${this.maxLines}`)
      const fullHeight = textContent.scrollHeight
      textContent.classList.add('bx-line-clamp', `bx-line-clamp--${this.maxLines}`)
      const clampedHeight = textContent.getBoundingClientRect().height
      this.contentSmallerThanInitialSize = fullHeight <= clampedHeight
    },
    // show the initial list items and display the show more button only if necessary
    showInitialListItems () {
      const listItems = this.$refs.content.querySelectorAll('li')
      if (listItems.length > this.maxListItems) {
        for (let i = 0; i < this.maxListItems; i++) {
          listItems[i].classList.add('bx-js-visible')
        }
      } else {
        this.contentSmallerThanInitialSize = true
      }
    }

  }
}
</script>
