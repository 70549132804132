<template v-if="ratings.length > 0">
  <div class="bx-content bx-epg-rating bx-typo--vod-epg">
    <h3>
      {{ headline }}
    </h3>
    <div class="bx-epg-rating__wrapper">
      <div v-for="{ name, value } in ratings"
           :key="name"
           class="bx-epg-rating__item">
        <div>
          <span v-for="bar in 3"
                :key="bar"
                :class="setBarClass(bar, value)" />
        </div>
        <span class="bx-typo-epg-rating-category">
          {{ name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    ratings: {
      type: Array,
      default: () => []
    },
    headline: {
      type: String,
      default: ''
    }
  },
  methods: {
    setBarClass (bar, value) {
      return {
        'bx-epg-rating__bar': true,
        'bx-epg-rating__bar--filled': value >= bar
      }
    }
  }
}
</script>
