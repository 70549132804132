<template>
  <div class="bx-tabs__container bx-typo--tabs"
       role="tablist"
       :class="{ 'slide-left': slideDirection === 'left', 'slide-right': slideDirection === 'right' }"
       @focusin="handleContainerFocus">
    <div class="bx-tabs__header"
         :class="{ 'bx-tabs__header--border-visible': headerBorderVisible }">
      <button
        v-for="(tab, index) in tabs"
        :id="`tab-${uniqueId}-${index}`"
        ref="tabButtons"
        :key="index"
        role="tab"
        :aria-controls="`panel-${uniqueId}-${index}`"
        :aria-selected="activeTab === index"
        :class="['bx-tabs__button', { 'bx-active': activeTab === index }]"
        :tabindex="activeTab === index ? 0 : -1"
        @click="selectTab(index)"
        @keydown="handleKeydown">
        {{ tab.title }}
      </button>
      <div class="bx-active-indicator"
           :style="indicatorStyle" />
    </div>
    <div class="bx-tabs__content">
      <slot />
    </div>
  </div>
</template>

<script>
import keepTabbingFocus from '../../../mixins/keepTabbingFocus.js'

function hashString (str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = ((hash << 5) - hash) + char
    hash = hash & hash // Convert to 32bit integer
  }
  return Math.abs(hash).toString(36)
}

export default {
  name: 'TabContainer',
  mixins: [keepTabbingFocus],
  provide () {
    this.uniqueId = null
    return {
      TabContainer: {
        registerTab: this.registerTab,
        unregisterTab: this.unregisterTab,
        containerId: null
      }
    }
  },
  props: {
    headerBorderVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tabs: [],
      activeTab: 0,
      slideDirection: 'right',
      indicatorStyle: {
        width: '0px',
        transform: 'translateX(0px)'
      },
      isContainerFocused: false,
      uniqueId: null,
      userInteracted: false
    }
  },
  mounted () {
    this.updateIndicator()
    window.addEventListener('resize', this.updateIndicator)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.updateIndicator)
    this.removeTabbingEventListeners()
  },
  methods: {
    handleContainerFocus () {
      this.isContainerFocused = true
    },
    updateIndicator () {
      this.$nextTick(() => {
        const activeButton = this.$refs.tabButtons?.[this.activeTab]
        if (activeButton) {
          const buttonRect = activeButton.getBoundingClientRect()
          const headerRect = activeButton.parentElement.getBoundingClientRect()
          this.indicatorStyle = {
            width: `${buttonRect.width}px`,
            transform: `translateX(${buttonRect.left - headerRect.left}px)`
          }
        }
      })
    },
    registerTab (tab) {
      this.tabs.push(tab)
      const tabTitles = this.tabs.map(t => t.title).join('-')
      this.uniqueId = `tabs-${hashString(tabTitles)}`
      if (this.TabContainer) {
        this.TabContainer.containerId = this.uniqueId
      }
      if (this.tabs.length === 1 || tab.selected) {
        this.selectTab(this.tabs.length - 1, false)
      }
    },
    unregisterTab (tab) {
      const index = this.tabs.indexOf(tab)
      if (index > -1) {
        this.tabs.splice(index, 1)
        if (index === this.activeTab && this.tabs.length) {
          this.selectTab(0, false)
        }
      }
    },
    selectTab (index, shouldFocus = true) {
      if (index < 0 || index >= this.tabs.length) return

      if (shouldFocus) {
        this.userInteracted = true
      }

      this.slideDirection = index > this.activeTab ? 'right' : 'left'
      this.activeTab = index
      this.tabs.forEach((tab, i) => {
        tab.isActive = (i === index)
        tab.tabIndex = i
      })

      this.updateIndicator()

      if (shouldFocus && this.userInteracted) {
        this.$nextTick(() => {
          const activeButton = this.$refs.tabButtons[this.activeTab]
          if (activeButton && document.body.classList.contains('bx-js-user-is-tabbing')) {
            this.removeTabbingEventListeners()
            this.keepTabbingFocus(this.$el, 'button[role="tab"]', this.activeTab)
          } else if (activeButton) {
            activeButton.focus()
          }
        })
      }
    },
    handleKeydown (event) {
      this.userInteracted = true

      let newIndex = this.activeTab

      switch (event.key) {
        case 'ArrowLeft':
        case 'ArrowUp':
          event.preventDefault()
          newIndex = this.activeTab - 1
          if (newIndex < 0) newIndex = this.tabs.length - 1
          break
        case 'ArrowRight':
        case 'ArrowDown':
          event.preventDefault()
          newIndex = this.activeTab + 1
          if (newIndex >= this.tabs.length) newIndex = 0
          break
        case 'Home':
          event.preventDefault()
          newIndex = 0
          break
        case 'End':
          event.preventDefault()
          newIndex = this.tabs.length - 1
          break
        case 'Tab':
          if ((event.shiftKey && this.activeTab > 0) ||
              (!event.shiftKey && this.activeTab < this.tabs.length - 1)) {
            event.preventDefault()
            if (event.shiftKey) {
              newIndex = this.activeTab - 1
              if (newIndex < 0) newIndex = this.tabs.length - 1
            } else {
              newIndex = this.activeTab + 1
              if (newIndex >= this.tabs.length) newIndex = 0
            }
            this.selectTab(newIndex)
          }
          return
        default:
          return
      }

      this.selectTab(newIndex)
    }
  }
}
</script>
