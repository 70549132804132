<template v-if="paragraphs && paragraphs.length > 0">
  <div class="bx-paragraph-list">
    <div v-for="(paragraph, index) in paragraphs"
         :key="paragraph.index"
         :data-lop="paragraph.listOrderPosition"
         :class="[addSelectorClass(paragraph.paragraphType, index), addClassOnLastHighlightedInRow(index), { 'bx-collection bx-ada--slot-wrapper': paragraph.enableRectangle1 }]">
      <component :is="paragraph.vueComponent.componentName"
                 v-if="paragraph.paragraphType === 'vueComponent'"
                 :params="paragraph.vueComponent.params" />
      <ParagraphTypeHtml v-else-if="paragraph.paragraphType === 'text'"
                         :html="paragraph.text"
                         :meta-data="metaData" />
      <ParagraphTypeHtml v-else-if="paragraph.paragraphType === 'raw'"
                         :html="paragraph.raw"
                         :meta-data="metaData" />
      <ParagraphTypeEmbed v-else-if="paragraph.paragraphType === 'embed'"
                          :embed="paragraph.embed" />
      <ParagraphTypeRecipe v-else-if="paragraph.paragraphType === 'recipe'"
                           :brand="brand"
                           :recipe="paragraph.recipe"
                           :page-meta="pageMeta">
        <template v-for="(_, slot) of $slots"
                  #[slot]="scope">
          <slot :name="slot"
                v-bind="scope" />
        </template>
      </ParagraphTypeRecipe>
      <ParagraphTypeIframe v-else-if="paragraph.paragraphType === 'iframe'"
                           v-bind="paragraph.iframe"
                           :include-depth="includeDepth" />
      <ParagraphTypeHorizontalLine v-else-if="paragraph.paragraphType === 'horizontalLine'"
                                   v-bind="paragraph.horizontalLine" />
      <ParagraphTypeInterline v-else-if="paragraph.paragraphType === 'interline'"
                              v-bind="paragraph.interline" />
      <ParagraphTypeImage v-else-if="paragraph.paragraphType === 'image'"
                          :image="paragraph.image"
                          :brand="brand"
                          :list-order-position="paragraph.listOrderPosition"
                          :type-count="typeCounter['image']" />
      <ParagraphTypeQuote v-else-if="paragraph.paragraphType === 'quote'"
                          v-bind="paragraph.quote" />
      <ParagraphTypeSlider v-else-if="paragraph.paragraphType === 'slider'"
                           v-bind="paragraph.slider"
                           :slider-no="index"
                           :brand="brand"
                           :meta-data="metaData"
                           :list-order-position="paragraph.listOrderPosition"
                           :include-depth="includeDepth" />
      <ParagraphTypeSocialStrip v-else-if="paragraph.paragraphType === 'socialStrip'"
                                :social-strip="paragraph.socialStrip" />
      <ParagraphTypeVideo v-else-if="paragraph.paragraphType === 'video'"
                          v-bind="paragraph.video"
                          :include-depth="includeDepth" />
      <ParagraphTypeWebForm v-else-if="paragraph.paragraphType === 'webForm'"
                            v-bind="paragraph.webForm" />
      <ParagraphTypeQuiz v-else-if="paragraph.paragraphType === 'quiz'"
                         v-bind="paragraph.quiz"
                         :list-order-position="paragraph.listOrderPosition"
                         :meta-data="metaData" />
      <ParagraphTypeRelatedTags v-else-if="paragraph.paragraphType === 'relatedTags'"
                                v-bind="paragraph.relatedTags" />
      <ParagraphTypeTagList v-else-if="paragraph.paragraphType === 'tagList'"
                            :tag-list="paragraph.tagList" />
      <ParagraphTypeTeaserGroup v-else-if="['teaserGroup', 'infoTeaser', 'vodEpgTeaserGroup'].includes(paragraph.paragraphType)"
                                v-bind="paragraph.teaserGroup || paragraph.infoTeaser || paragraph.vodEpgTeaserGroup"
                                :include-depth="includeDepth"
                                :list-order-position="paragraph.listOrderPosition"
                                :data-is-slider-child="dataIsSliderChild"
                                :slider-no="paragraph.listOrderPosition + includeDepth + typeCounter[paragraph.paragraphType]"
                                :brand="brand" />
      <ParagraphTypeProductGroup v-else-if="paragraph.paragraphType === 'productGroup'"
                                 :brand="brand"
                                 :is-content-commerce="metaData.isContentCommerce"
                                 :slider-no="paragraph.listOrderPosition"
                                 :product-group="paragraph.productGroup" />
      <ParagraphTypeProductAutoComparison v-else-if="paragraph.paragraphType === 'productAutoComparison'"
                                          v-bind="paragraph.productAutoComparison"
                                          :is-content-commerce="metaData.isContentCommerce"
                                          :brand="brand" />
      <ParagraphTypeChildTagsTeaserGroup v-else-if="paragraph.paragraphType === 'childTagsTeaserGroup'"
                                         v-bind="paragraph.childTagsTeaserGroup"
                                         :include-depth="includeDepth"
                                         :list-order-position="paragraph.listOrderPosition"
                                         :brand="brand" />
      <ParagraphTypeTeaserGroup v-else-if="paragraph.paragraphType === 'pageableTeaserGroup'"
                                v-bind="paragraph.pageableTeaserGroup"
                                :include-depth="includeDepth"
                                :slider-no="paragraph.listOrderPosition + includeDepth + typeCounter[paragraph.paragraphType]"
                                :brand="brand" />
      <ParagraphTypeInlineTeaser v-else-if="paragraph.paragraphType === 'teaser'"
                                 :composed-teaser="paragraph.teaser"
                                 :list-order-position="paragraph.listOrderPosition"
                                 :meta-data="{brand: brand}" />
      <ParagraphTypeDummyContainer v-else-if="paragraph.paragraphType === 'dummyContainer'"
                                   :dummy-data="paragraph.dummyContainerData" />
      <ParagraphTypeParagraphCollection v-else-if="paragraph.paragraphType === 'paragraphCollection'"
                                        :paragraphs="paragraph.paragraphs"
                                        :trigger-ad-embed="paragraph.triggerAdEmbed"
                                        :meta-data="metaData"
                                        :page-meta="pageMeta"
                                        :page-type="pageType"
                                        :brand="brand"
                                        :include-depth="includeDepth">
        <template v-for="(_, slot) of $slots"
                  #[slot]="scope">
          <slot :name="slot"
                v-bind="scope" />
        </template>
      </ParagraphTypeParagraphCollection>
      <ParagraphTypeStructureTermTree v-else-if="paragraph.paragraphType === 'structureTermTree'"
                                      :data="paragraph.structureTermTree" />
      <ParagraphTypeAuthorBox v-else-if="paragraph.paragraphType === 'authorBox'"
                              :author-box="paragraph.authorBox"
                              :brand="brand" />
      <ParagraphTypeContentBox v-else-if="paragraph.paragraphType === 'contentBox'"
                               v-bind="paragraph.contentBox"
                               :brand="brand" />
      <ParagraphTypeAdviceWidget v-else-if="paragraph.paragraphType === 'aureaWidget'"
                                 v-bind="paragraph.aureaWidget"
                                 :brand="brand" />
      <ParagraphTypeFaq v-else-if="paragraph.paragraphType === 'faq'"
                        v-bind="paragraph.faq" />
      <ParagraphTypeAffiliateLink v-else-if="paragraph.paragraphType === 'affiliateLink'"
                                  v-bind="paragraph.affiliateLink" />
      <ParagraphTypeAdditionalLinks v-else-if="paragraph.paragraphType === 'additionalLinks'"
                                    :additional-links="paragraph.additionalLinks"
                                    :list-order-position="paragraph.listOrderPosition"
                                    :include-depth="includeDepth" />
      <ParagraphTypeListOfSources v-else-if="paragraph.paragraphType === 'listOfSources'"
                                  :list-order-position="paragraph.listOrderPosition"
                                  v-bind="paragraph.listOfSources" />
      <ParagraphTypeAdaAdSlot v-else-if="paragraph.paragraphType === 'adaAdSlot'"
                              v-bind="paragraph.adaAdSlot" />
      <ParagraphTypeAdaAutoNative v-else-if="paragraph.paragraphType === 'adaAutoNative'" />
      <ParagraphTypeAdaPagestrip v-else-if="paragraph.paragraphType === 'adaPagestrip'" />
      <ParagraphTypeAdaNativeCrossbar v-else-if="paragraph.paragraphType === 'adaNativeCrossbar' && allowNativeTeasers"
                                      v-bind="paragraph.adaNativeCrossbar" />
      <ParagraphTypeTable v-else-if="paragraph.paragraphType === 'table' && paragraph.table"
                          :table="paragraph.table" />
      <ParagraphTypeTableOfContents v-else-if="paragraph.paragraphType === 'tableOfContents'"
                                    v-bind="paragraph.tableOfContents" />
      <ParagraphTypeHotOrNot v-else-if="paragraph.paragraphType === 'hotOrNot' && paragraph.hotOrNot"
                             :hot-or-not="paragraph.hotOrNot"
                             :brand="brand" />
      <ParagraphTypePoll v-else-if="paragraph.paragraphType === 'poll' && paragraph.poll"
                         :poll="paragraph.poll"
                         :list-order-position="paragraph.listOrderPosition"
                         :brand="brand" />
      <ParagraphTypeNewsletterTeaser v-else-if="paragraph.paragraphType === 'newsletterTeaser'" />
      <ParagraphTypeAscendantCalculator v-else-if="paragraph.paragraphType === 'ascendantCalculator'" />
      <ParagraphTypeHoroscope v-else-if="paragraph.paragraphType === 'horoscope'"
                              :brand="brand"
                              :paragraph="paragraph.horoscope" />
      <ParagraphTypeMoonRun v-else-if="paragraph.paragraphType === 'moonrun'"
                            :moon-run="paragraph.moonrun" />
      <ParagraphTypeButtonGroup v-else-if="paragraph.paragraphType === 'buttonGroup'"
                                :button-group="paragraph.buttonGroup" />
      <ParagraphTypeFileDownload v-else-if="paragraph.paragraphType === 'fileDownload'"
                                 v-bind="paragraph.fileDownload"
                                 :brand="brand" />
      <ParagraphTypeLexicon v-else-if="paragraph.paragraphType === 'lexicon'"
                            :paragraph="paragraph.lexicon"
                            :include-depth="includeDepth"
                            :brand="brand" />
      <ParagraphTypeSearch v-else-if="paragraph.paragraphType === 'search'"
                           :search="{ ...paragraph.search, fuzzy: true }"
                           :brand="brand" />
      <ParagraphTypeContactForm v-else-if="paragraph.paragraphType === 'contactForm'"
                                :contact-form="paragraph.contactForm"
                                :brand="brand" />
      <ParagraphTypeInfoBar v-else-if="paragraph.paragraphType === 'infoBar'"
                            v-bind="paragraph.infoBar" />
      <ParagraphTypeVodRating v-else-if="paragraph.paragraphType === 'vodRating'"
                              v-bind="paragraph.vodRating" />
      <ParagraphTypeVodEpgBroadcastSlider v-else-if="paragraph.paragraphType === 'vodEpgBroadcastSlider'"
                                          :brand="brand"
                                          :trailer-items="paragraph.vodEpgBroadcastSlider.trailerItems"
                                          :image-items="paragraph.vodEpgBroadcastSlider.imageItems" />
      <ParagraphTypeVodStreamingOptions v-else-if="paragraph.paragraphType === 'vodStreamingOptions'"
                                        v-bind="paragraph.vodStreamingOptions" />
      <ParagraphTypeVodSeasonWithEpisodes v-else-if="paragraph.paragraphType === 'vodSeasonWithEpisodes'"
                                          :list-order-position="paragraph.listOrderPosition"
                                          v-bind="paragraph.vodSeasonWithEpisodes" />
      <ParagraphTypeEpgBroadcastRating v-else-if="paragraph.paragraphType === 'epgBroadcastRating'"
                                       :list-order-position="paragraph.listOrderPosition"
                                       v-bind="paragraph.epgBroadcastRating" />
      <ParagraphTypeAdDividingLine v-else-if="paragraph.paragraphType === 'adDividingLine'"
                                   v-bind="paragraph.adDividingLine" />
      <ParagraphTypeTabbedInfo v-else-if="paragraph.paragraphType === 'tabbedInfo'"
                               v-bind="paragraph.tabbedInfo" />

      <ParagraphTypeAdaAdSlot v-if="index === 0 && includeDepth === 0 && staticMobile1OnHome"
                              slot-name="mobile"
                              :slot-id="1" />
      <ParagraphTypeAdaAdSlot v-else-if="paragraph.enableRectangle1"
                              slot-name="rectangle"
                              :slot-id="1" />
      <AdaSlotMarker v-else-if="dynamicAdSlotsEnabled && !blockAds && includeDepth === 0"
                     v-bind="{ aboveParagraph: { index, type: paragraph.paragraphType } }" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { useAdaStore } from '../stores/ada'
/**
 * Some Paragraph Types return raw HTML to be injected into the component
 * These will be of type: text, raw, video, embed
 * To avoid duplication of one-liner components, we will use the same component for all of those types.
 */
import {
  ParagraphTypeAdaAdSlot,
  ParagraphTypeAdaNativeCrossbar,
  ParagraphTypeAdaAutoNative,
  ParagraphTypeAdaPagestrip,
  ParagraphTypeAdditionalLinks,
  ParagraphTypeAffiliateLink,
  ParagraphTypeAdviceWidget,
  ParagraphTypeFaq,
  ParagraphTypeAuthorBox,
  ParagraphTypeButtonGroup,
  ParagraphTypeChildTagsTeaserGroup,
  ParagraphTypeConsentRevokeButton,
  ParagraphTypeContactForm,
  ParagraphTypeContentBox,
  ParagraphTypeDummyContainer,
  ParagraphTypeEmbed,
  ParagraphTypeFileDownload,
  ParagraphTypeHorizontalLine,
  ParagraphTypeHoroscope,
  ParagraphTypeHotOrNot,
  ParagraphTypeHtml,
  ParagraphTypeIframe,
  ParagraphTypeImage,
  ParagraphTypeInlineTeaser,
  ParagraphTypeInterline,
  ParagraphTypeLexicon,
  ParagraphTypeListOfSources,
  ParagraphTypeMoonRun,
  ParagraphTypeNewsletterTeaser,
  ParagraphTypeAscendantCalculator,
  ParagraphTypeParagraphCollection,
  ParagraphTypePoll,
  ParagraphTypeProductGroup,
  ParagraphTypeQuiz,
  ParagraphTypeQuote,
  ParagraphTypeRecipe,
  ParagraphTypeRelatedTags,
  ParagraphTypeSearch,
  ParagraphTypeSlider,
  ParagraphTypeSocialStrip,
  ParagraphTypeStructureTermTree,
  ParagraphTypeTable,
  ParagraphTypeTableOfContents,
  ParagraphTypeTagList,
  ParagraphTypeTeaserGroup,
  ParagraphTypeUserActivation,
  ParagraphTypeUserLogin,
  ParagraphTypeUserPasswordChange,
  ParagraphTypeUserPasswordReset,
  ParagraphTypeUserRegister,
  ParagraphTypeUserSubscription,
  ParagraphTypeVideo,
  ParagraphTypeWebForm,
  ParagraphTypeProductAutoComparison,
  ParagraphTypeInfoBar,
  ParagraphTypeVodRating,
  ParagraphTypeVodEpgBroadcastSlider,
  ParagraphTypeVodStreamingOptions,
  ParagraphTypeVodSeasonWithEpisodes,
  ParagraphTypeEpgBroadcastRating,
  ParagraphTypeAdDividingLine,
  ParagraphTypeTabbedInfo,
  AdaSlotMarker
} from './paragraph-types'

export default {
  name: 'Paragraphs',
  components: {
    ParagraphTypeHtml,
    ParagraphTypeInterline,
    ParagraphTypeImage,
    ParagraphTypeQuote,
    ParagraphTypeSlider,
    ParagraphTypeSocialStrip,
    ParagraphTypeVideo,
    ParagraphTypeWebForm,
    ParagraphTypeQuiz,
    ParagraphTypeAdaAdSlot,
    ParagraphTypeAdaNativeCrossbar,
    ParagraphTypeAdaAutoNative,
    ParagraphTypeAdaPagestrip,
    ParagraphTypeEmbed,
    ParagraphTypeIframe,
    ParagraphTypeInlineTeaser,
    ParagraphTypeDummyContainer,
    ParagraphTypeTeaserGroup,
    ParagraphTypeChildTagsTeaserGroup,
    ParagraphTypeParagraphCollection,
    ParagraphTypeRelatedTags,
    ParagraphTypeTagList,
    ParagraphTypeHorizontalLine,
    ParagraphTypeStructureTermTree,
    ParagraphTypeAuthorBox,
    ParagraphTypeContentBox,
    ParagraphTypeAdviceWidget,
    ParagraphTypeFaq,
    ParagraphTypeAffiliateLink,
    ParagraphTypeAdditionalLinks,
    ParagraphTypeListOfSources,
    ParagraphTypeMoonRun,
    ParagraphTypeTable,
    ParagraphTypeTableOfContents,
    ParagraphTypeHotOrNot,
    ParagraphTypePoll,
    ParagraphTypeNewsletterTeaser,
    ParagraphTypeAscendantCalculator,
    ParagraphTypeHoroscope,
    ParagraphTypeButtonGroup,
    ParagraphTypeFileDownload,
    ParagraphTypeProductGroup,
    ParagraphTypeLexicon,
    ParagraphTypeSearch,
    ParagraphTypeContactForm,
    ParagraphTypeConsentRevokeButton,
    ParagraphTypeUserRegister,
    ParagraphTypeUserActivation,
    ParagraphTypeUserLogin,
    ParagraphTypeUserPasswordReset,
    ParagraphTypeUserPasswordChange,
    ParagraphTypeUserSubscription,
    ParagraphTypeRecipe,
    ParagraphTypeProductAutoComparison,
    ParagraphTypeInfoBar,
    ParagraphTypeVodRating,
    ParagraphTypeVodEpgBroadcastSlider,
    ParagraphTypeVodStreamingOptions,
    ParagraphTypeVodSeasonWithEpisodes,
    ParagraphTypeEpgBroadcastRating,
    ParagraphTypeAdDividingLine,
    ParagraphTypeTabbedInfo,
    AdaSlotMarker
  },
  props: {
    // The collection of paragraphs
    paragraphs: {
      type: Array,
      default: () => []
    },
    metaData: {
      type: Object,
      default: null
    },
    brand: {
      type: String,
      default: null
    },
    includeDepth: {
      type: Number,
      default: 0
    },
    pageType: {
      type: String,
      default: null
    },
    pageMeta: {
      type: Object,
      default: () => {}
    },
    dataIsSliderChild: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      typeCounter: {},
      countedParagraphs: {}
    }
  },
  computed: {
    ...mapState(useAdaStore, ['dynamicAdSlotsEnabled', 'blockAds', 'staticMobile1OnHome']),
    allowNativeTeasers () {
      return !this.metaData?.disallowNativeTeasers
    }
  },
  methods: {
    addClassOnLastHighlightedInRow (index) {
      if (index >= 0) {
        const prevIndex = index - 1
        const nextIndex = index + 1
        const classes = []
        if (this.paragraphs[index].teaserGroup && this.paragraphs[index].teaserGroup.highlighted === true) {
          if (this.paragraphs[prevIndex] === undefined ||
            (this.paragraphs[prevIndex] && this.paragraphs[prevIndex].paragraphType !== 'teaserGroup') ||
            (this.paragraphs[prevIndex].paragraphType === 'teaserGroup' && this.paragraphs[prevIndex].teaserGroup && this.paragraphs[prevIndex].teaserGroup.highlighted !== true)) {
            classes.push('bx-teaser-container--highlight-first')
          }
          if (this.paragraphs[nextIndex] === undefined ||
            (this.paragraphs[nextIndex] && this.paragraphs[nextIndex].paragraphType !== 'teaserGroup') ||
            (this.paragraphs[nextIndex].paragraphType === 'teaserGroup' && this.paragraphs[nextIndex].teaserGroup && this.paragraphs[nextIndex].teaserGroup.highlighted !== true)) {
            classes.push('bx-teaser-container--highlight-followup')
          }
        }
        return classes
      }
    },
    addSelectorClass (type, index) {
      const countIndex = `${this.includeDepth}-${index}`
      if (!this.countedParagraphs[countIndex]) {
        this.typeCounter[type] = this.typeCounter[type] === undefined ? 1 : this.typeCounter[type] + 1
        this.countedParagraphs[countIndex] = this.typeCounter[type]
      }
      return `bx-content--${this.kebabCase(type)} bx-js-${this.kebabCase(type)}-${this.includeDepth}-${this.countedParagraphs[countIndex]}`
    },
    kebabCase (inputString) {
      return inputString && inputString.length > 0 ? inputString.replace(/([A-Z])/g, '-$1').toLowerCase() : ''
    }
  }
}
</script>
