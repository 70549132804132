<template>
  <div class="bx-content bx-streaming-offers bx-typo--vod-epg bx-typo--streaming-offers">
    <h3 class="bx-streaming-offers__heading">
      {{ heading }}
    </h3>
    <TabContainer>
      <Tab v-for="tab in tabs"
           :key="tab.title"
           :title="tab.title">
        <div class="bx-streaming-offers__monetization-type"
             :data-tc="`streaming-offers-${tab.type}`">
          <span v-if="!getTabData(tab.dataKey)?.length"
                class="bx-typo--streaming-offers-empty">
            {{ noOffersAvailableMessage }}
          </span>
          <ul class="bx-streaming-offers__offer">
            <li v-for="offer in getTabData(tab.dataKey)"
                :key="offer.id"
                class="bx-streaming-offers__offer__content"
                :title="offer.provider.clearName">
              <a :href="offer.urls.standardWeb"
                 target="_blank"
                 rel="noopener">
                <img :src="offer.provider.iconUrl"
                     :alt="offer.provider.clearName"
                     class="bx-icon--provider-icon">
                <span class="bx-streaming-offers__price">{{ getPrice(offer) }}</span>
              </a>
            </li>
          </ul>
        </div>
      </Tab>
    </TabContainer>
    <span class="bx-streaming-offers__footer">
      powered by
      <a :href="'https://www.justwatch.com/'"
         target="_blank"
         rel="noopener">
        <img src="/images/tvmovie/justwatch-logo.svg"
             alt="Justwatch Logo"
             class="bx-icon--justwatch-logo">
      </a>
    </span>
  </div>
</template>

<script>
import { TabContainer, Tab } from '../shared/tabs'

export default {
  name: 'ParagraphTypeVodStreamingOptions',
  components: {
    TabContainer,
    Tab
  },
  props: {
    /** @public Needed for the data */
    /* eslint-disable-next-line vue/no-unused-properties */
    free: {
      type: Array,
      default: () => []
    },
    /** @public Needed for the data */
    /* eslint-disable-next-line vue/no-unused-properties */
    flatrate: {
      type: Array,
      default: () => []
    },
    /** @public Needed for the data */
    /* eslint-disable-next-line vue/no-unused-properties */
    buy: {
      type: Array,
      default: () => []
    },
    /** @public Needed for the data */
    /* eslint-disable-next-line vue/no-unused-properties */
    rent: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tabs () {
      return [
        { title: 'ABO', type: 'subscription', dataKey: 'flatrate' },
        { title: 'GRATIS', type: 'free', dataKey: 'free' },
        { title: 'KAUFEN', type: 'buy', dataKey: 'buy' },
        { title: 'LEIHEN', type: 'rent', dataKey: 'rent' }
      ]
    },
    heading () {
      return 'Streamingoptionen'
    },
    noOffersAvailableMessage () {
      return 'Keine Angebote verfügbar'
    }
  },
  methods: {
    getPrice (offer) {
      return offer.retailPrice ? 'ab ' + offer.retailPrice + (offer.currency === 'EUR' ? '€' : ' ' + offer.currency) : ''
    },
    getTabData (key) {
      return this[key] || []
    }
  }
}
</script>
