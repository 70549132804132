<template>
  <div class="bx-typo bx-page-wrapper">
    <Notify />
    <GoogleTagManager />
    <IvwTags :page-data="pageData" />
    <Agf :page-data="pageData"
         :brand="brand" />
    <Pressboard v-if="rsConfig.pressboard.enabled && pageData.metaData.advertorial && isArticleOrArticleVersion"
                :page-data="pageData" />
    <VgWort v-if="rsConfig.vgWort.enabled"
            :page-meta="pageData.pageMeta" />
    <PreviewTimer v-if="pageData && pageData.pageMeta && pageData.pageMeta.previewToken"
                  :preview-token="pageData.pageMeta.previewToken" />
    <PageHeader v-if="pageData && pageData.header"
                :header-data="pageData.header"
                :brand="brand">
      <template v-for="(_, slot) of $slots"
                #[slot]="scope">
        <slot :name="slot"
              v-bind="scope" />
      </template>
    </PageHeader>
    <div id="bx-grid-wrapper"
         class="bx-grid-wrapper bx-normalize">
      <div :class="pageTypeStyleClass">
        <div class="bx-ada bx-ada--top-slots bx-ada--superbanner_1-wallpaper_1">
          <AdaAdSlot slot-name="wallpaper"
                     :slot-id="1" />
          <AdaAdSlot slot-name="superbanner"
                     :slot-id="1" />
        </div>
        <AdaAdSlot v-if="!dynamicAdSlotsEnabled"
                   slot-name="mobile"
                   :slot-id="1" />
        <AdaAdSlot slot-name="skyscraper"
                   :slot-id="1" />
        <PageContent v-if="pageData && pageData.pageMeta && pageData.metaData && pageData.body"
                     :header-data="pageData.header"
                     :page-meta="pageData.pageMeta"
                     :meta-data="pageData.metaData"
                     :asset="pageData.body"
                     :brand="brand">
          <template v-for="(_, slot) of $slots"
                    #[slot]="scope">
            <slot :name="slot"
                  v-bind="scope" />
          </template>
        </PageContent>

        <div v-if="isArticleOrArticleVersion || pageData?.metaData?.behaveLikeArticle"
             class="bx-grid--container">
          <div :class="{ 'bx-collection': !dynamicAdSlotsEnabled }">
            <div :class="{ 'bx-collection__inner-wrapper': !dynamicAdSlotsEnabled }">
              <AdTaboola v-if="isTaboolaEnabled()" />
            </div>
            <AdaAdSlot v-if="!dynamicAdSlotsEnabled && pageData.pageMeta?.adSlots?.rectangle2?.enableInPageWrapper"
                       slot-name="rectangle"
                       :slot-id="2" />
            <div v-if="!dynamicAdSlotsEnabled && pageData.pageMeta?.adSlots?.rectangle2?.enableInPageWrapper"
                 class="bx-collection__no-ad-fallback-width" />
          </div>
        </div>

        <div class="bx-grid--container">
          <AdaNativeCrossbar v-if="isNotAdvertorial && allowNativeTeasers"
                             position="bottom" />
        </div>

        <AdaAdSlot slot-name="dmofooter"
                   :slot-id="1" />
        <AdaAdSlot slot-name="mobile"
                   :slot-id="10" />
        <div id="pressboard-ad-sponsorship-msg" />
      </div>
    </div>
    <slot v-if="pageData && pageData.footer"
          name="footer" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useRsVersionStore } from '../stores/rsVersion'
import { useSourcepointStore } from '../stores/sourcepoint'
import { useSessionStore } from '../stores/session'
import { useConfigStore } from '../stores/config'
import { useAdaStore } from '../stores/ada'
import { usePageStore } from '../stores/page'
import clientScriptLoader from '../mixins/client-script-loader'
import customDimensions from '../mixins/custom-dimensions'
import meta from '../mixins/meta'
import AdaAdSlot from './ad-slots/AdaAdSlot.vue'
import AdaNativeCrossbar from './ad-slots/AdaNativeCrossbar.vue'
import AdTaboola from './ad-slots/AdTaboola.vue'
import PageContent from './PageContent.vue'
import PageHeader from './PageHeader.vue'
import PreviewTimer from './PreviewTimer.vue'
import Notify from './shared/Notify.vue'
import GoogleTagManager from './tracking/GoogleTagManager.vue'
import IvwTags from './tracking/IvwTags.vue'
import Agf from './tracking/Agf.vue'
import Pressboard from './tracking/Pressboard.vue'
import VgWort from './tracking/VgWort.vue'

export default {
  components: {
    AdaAdSlot,
    AdaNativeCrossbar,
    PreviewTimer,
    PageHeader,
    PageContent,
    GoogleTagManager,
    AdTaboola,
    IvwTags,
    Agf,
    Notify,
    Pressboard,
    VgWort
  },
  mixins: [
    clientScriptLoader,
    meta,
    customDimensions
  ],
  props: {
    pageData: {
      type: Object,
      default: () => {}
    },
    brand: {
      type: String,
      default: ''
    }
  },
  head () { // for the sake of documentation: this function has been moved from meta mixin to this component
    return this.generateMetas()
  },
  computed: {
    // Some of these store props are also or only used by mixins
    ...mapState(useRsVersionStore, ['version']),
    ...mapState(useSessionStore, ['hasPurSub']),
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(useSourcepointStore, ['consentConfig', 'noScripts', 'consentsExist']),
    ...mapState(useAdaStore, ['isForceTestZone', 'dynamicAdSlotsEnabled']),
    ...mapState(usePageStore, ['isArticleOrArticleVersion']),
    pageTypeStyleClass () {
      // convert page type name to css class name
      const pageTypeClass = this.pageData?.pageMeta?.pageType && this.pageData.pageMeta.pageType.length > 0 && !(this.pageData.metaData?.behaveLikeArticle) ? 'bx-page--' + this.pageData.pageMeta.pageType : 'bx-page--article'
      const articleTypeClass = this.pageData?.pageMeta?.articleType ? 'bx-article--' + this.pageData.pageMeta.articleType : ''
      const dsTableModifierClass = this.pageData?.pageMeta?.pageType === 'structureterm' && this.pageData?.pageMeta?.url?.match(/\/datenschutz/i) ? 'bx-page--datenschutz' : '' // Datenschutz-Tabellen modifier
      return {
        'bx-grid': true,
        [pageTypeClass]: true,
        [articleTypeClass]: true,
        [dsTableModifierClass]: dsTableModifierClass.length > 0
      }
    },
    isNotAdvertorial () {
      return !!(this.pageData.metaData.advertorial === undefined || !this.pageData.metaData.advertorial)
    },
    allowNativeTeasers () {
      return !this.pageData.metaData.disallowNativeTeasers
    }
  },
  watch: {
    isPrintPreview (newValue) {
      this.specialAdPrintStyle(newValue)
    }
  },
  beforeMount () {
    const forceTestZone = this.getQueryParams()?.ftz
    if (forceTestZone) {
      this.setTestZone(forceTestZone)
    } else {
      this.checkForceTestZoneCookie()
    }
    if (this.getQueryParams()?.ada === '1') {
      this.forcePreviewBundle()
    } else if (this.isForceTestZone) {
      // apply testZone=develop to URL - not loosing existing query params
      const urlParams = new URLSearchParams(window.location.search)
      if (!urlParams.has('testZone')) {
        urlParams.set('testZone', 'develop')
      }
      history.pushState(null, '', `?${urlParams.toString()}`)
    }
  },
  async mounted () {
    this.refreshCustomVendorConsents()
    this.setBrandPageStyleClass()

    window.__tcfapi?.('addEventListener', 2, async (tcData, success) => {
      if (success && tcData.eventStatus === 'useractioncomplete') {
        this.consentActionPerformed()
      }
    })
  },
  methods: {
    // Some of these store props are also or only used by mixins
    ...mapActions(useAdaStore, ['forcePreviewBundle', 'setTestZone', 'checkForceTestZoneCookie']),
    ...mapActions(useSourcepointStore, ['refreshCustomVendorConsents', 'consentActionPerformed']),
    ...mapActions(usePageStore, ['setClientScriptsLoaded']),
    setBrandPageStyleClass () {
      if (this.pageData.metaData.advertorial) {
        document.getElementsByTagName('body')[0].classList.add('bx-page-style--advertorial')
      }
    },
    isTaboolaEnabled () {
      return !this.hasPurSub && !this.pageData.metaData.advertorial && !this.pageData.metaData.disallowTaboola ? this.rsConfig.adSlotUsage.taboola.enabled : false
    },
    specialAdPrintStyle (isPrintPreview) {
      const specialAds = Array.from(document.querySelectorAll('[id]')).filter(el => /(superbanner|leaderboard)/.test(el.id))

      if (isPrintPreview) {
        specialAds.forEach(el => {
          el.style.display = 'none'
        })
      } else {
        specialAds.forEach(el => {
          el.removeAttribute('style')
        })
      }
    }
  }
}
</script>
