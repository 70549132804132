<template>
  <ul class="bx-content bx-content__meta bx-typo--content-meta">
    <li v-for="(key, index) in orderedKeys"
        :key="index">
      {{ formattedValue(key, metaInfos[key]) }}
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    metaInfos: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // defined order of metaInfos
      keyOrder: ['airDate', 'airTime', 'country', 'year', 'genre', 'runtime', 'channelName']
    }
  },
  computed: {
    orderedKeys () {
      // fallback index for keys not already in keyOrder
      const fallbackIndex = this.keyOrder.length
      // order the keys according to the defined order
      return Object.keys(this.metaInfos).sort((a, b) => {
        const indexA = this.keyOrder.indexOf(a)
        const indexB = this.keyOrder.indexOf(b)
        return (indexA === -1 ? fallbackIndex : indexA) - (indexB === -1 ? fallbackIndex : indexB)
      })
    }
  },
  methods: {
    formattedValue (key, value) {
      return key === 'runtime' ? `${value} min.` : value
    }
  }
}
</script>
