<template>
  <div class="bx-content bx-typo--vod-epg">
    <h3>
      Bewertung
    </h3>
    <div class="bx-vod-rating bx-typo--vod-rating">
      <img src="/images/tvmovie/tmdb-logo-vod-rating.svg"
           alt="TMDB Logo"
           class="tmdb-logo">
      <span>
        <span>
          Bewertung: {{ value.toFixed(1).replace('.', ',') }}
        </span>
        / 10
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    provider: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: 0
    }
  }
}
</script>
