<template>
  <div class="bx-content__badges bx-typo--content-badges"
       :class="{ 'bx-content': !inlineUsage }">
    <span v-for="badge in badgeList"
          :key="badge"
          :data-badge="badge.toLowerCase()">
      {{ badge }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    badges: {
      type: Object,
      default: () => {}
    },
    inlineUsage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      badgeText: {
        isPopular: 'beliebt',
        isNew: 'neu',
        isTip: 'Tipp',
        isDailyTip: 'Tagestipp',
        isTopTip: 'Top-Tipp',
        isLive: 'Live',
        isHD: 'HD',
        is16to9: '16:9',
        isDolbyDigital: 'Dolby Digital',
        isLastBroadcast: 'Letzte Ausstrahlung',
        isYouthProtected: 'Jugendschutz'
      }
    }
  },
  computed: {
    badgeList () {
      const badges = this.badges || {}
      return Object.keys(badges)
        .filter(key => this.badges[key]) // only keys with truthy values
        .map(key => {
          const lowercaseKey = key.replace(/^is/, '').toLowerCase() // remove "is" and make lowercase
          return this.badgeText[key] || lowercaseKey // final text or lowercase key as name to show
        })
    }
  }
}
</script>
