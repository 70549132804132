<template>
  <span />
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import EventBus from '../../event-bus/EventBus'
import deviceDetection from '../../mixins/device-detection'
import urlHelper from '../../mixins/url-helper'

/*
  How do I ensure functionality?
  ------------------------------
  Filter HTTP traffic with the string 'nmr' to capture traffic from the SDK.
  This will capture traffic going to Nielsen servers.
*/
export default {
  mixins: [deviceDetection, urlHelper],
  props: {
    pageData: {
      type: Object,
      default: () => { return {} }
    },
    brand: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      nSdkInstance: null,
      latestAgfMetaData: {}
    }
  },
  head () {
    return {
      script: [
        ...this.agfActivated
          ? [{
              'data-vmid': 'agf-static-queue-snippet',
              key: 'agf-static-queue-snippet',
              type: 'text/javascript',
              async: false,
              innerHTML: `
                !(function (e, n) {
                  function t (e) {
                    return typeof e === 'object' ? JSON.parse(JSON.stringify(e)) : e
                  }
                  e[n] = e[n] || {
                    nlsQ: function (o, r, c) {
                      const s = e.document
                      const a = s.createElement('script')
                      a.async = 1,
                      a.src = (e.location.protocol === 'http:' ? 'http:' : 'https:') + '//cdn-gl.nmrodam.com/conf/' + o + '.js#name=' + r + '&ns=' + n
                      const i = s.getElementsByTagName('script')[0]
                      return i.parentNode.insertBefore(a, i),
                      e[n][r] = e[n][r] || {
                        g: c || {},
                        ggPM: function (o, c, s, a, i) { e[n][r].q = e[n][r].q || []; try { const l = t([o, c, s, a, i]); e[n][r].q.push(l) } catch (e) { console && console.log && console.log('Error: Cannot register event in Nielsen SDK queue.') } },
                        trackEvent: function (o) { e[n][r].te = e[n][r].te || []; try { const c = t(o); e[n][r].te.push(c) } catch (e) { console && console.log && console.log('Error: Cannot register event in Nielsen SDK queue.') } }
                      },
                      e[n][r]
                    }
                  }
                }(window, 'NOLBUNDLE'))
              `
            }]
          : []
      ]
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    // ensure that agf measurement is only acivated while config is enabled and valid and we are not in development mode
    agfActivated () {
      return this.agfConfig.enabled && this.isAgfConfigValid && process.env.NODE_ENV !== 'development'
    },
    // just a shortcut to the AGF config object
    agfConfig () {
      return this.rsConfig.agfConfig
    },
    // ensure that all necessary AGF config values are set
    isAgfConfigValid () {
      return !!this.agfConfig.vcid.trim() &&
        Object.values(this.agfConfig.appId).every(value => value.trim() !== '') &&
        !!this.agfConfig.segAPrefix.trim()
    },
    // create a path string from the mainStructurePath array
    section () {
      const path = this.pageData.body.mainStructurePath
        .slice(1) // remove first element ('Home')
        .map(item => item?.url?.replace('/', '')) // clean URL if available
        .filter(url => url && url !== '') // remove empty and undefined values
        .join('_') // concatenate with '_'

      // set brand name as prefix
      return this.brand + (path ? `_${path}` : '')
    },
    // generate the segA value for the AGF measurement based on the IVWTag logic
    segA () {
      return this.agfConfig.segAPrefix + '_' + (this.pageData?.metaData?.IVWTag || '')
    }
  },
  mounted () {
    if (this.agfActivated) {
      // debug option disabled by default - enable if you need some noise in the browser console
      const enableDebugging = false
      // prevent debug output in staging and production environment
      const debugOptions = enableDebugging && ['development', 'testing'].includes(process.env.NODE_ENV) ? { nol_sdkDebug: 'debug' } : {}
      // SDK Initialization
      this.nSdkInstance = NOLBUNDLE.nlsQ(this.rsConfig.agfConfig.appId.static, 'nlsnInstance', debugOptions)
      // Event 'staticstart' Call
      this.nSdkInstance.ggPM('staticstart', this.agfMetaData())
      // Handle back-forward cache of the browser and start the measurement of the new page loaded from bfcache
      this.backForwardCacheHandler()
    }
    // listen to global event
    EventBus.$on('triggerAGF', this.triggerAdvertisingMedium)
  },
  beforeUnmount () {
    // remove global event listener
    EventBus.$off('triggerAGF', this.triggerAdvertisingMedium)
  },
  methods: {
    triggerAdvertisingMedium () {
      if (this.agfActivated) {
        // Stop static measurement for the page => Event 'staticend' Call
        this.nSdkInstance.ggPM('staticend', this.latestAgfMetaData)
        // Start static measurement for the advertising medium after user interaction (new metadata) => Event 'staticstart' Call
        this.nSdkInstance.ggPM('staticstart', this.agfMetaData('WPI'))
      }
    },
    agfMetaData (segB = 'CPI') {
      // we'll save the latest metadata for the page to terminate the measurement of the previous page within 'staticend' event
      this.latestAgfMetaData = {
        type: 'static',
        assetid: this.pageData.pageMeta.id,
        section: this.section,
        segA: this.segA, // equal to old IVW 'cp' value
        segB, // 'CPI' for Content || 'WPI' for 'Werbetraeger' (after user interaction with the site and newly loaded Content)
        segC: 'UNKNOWN', // meant is an identifier for the program brand, currently still Default UNKNOWN, possibly relevant in the future
        subbrand: this.agfConfig.vcid
      }
      // try to remember the latest metadata for the page for the back-forward cache handling in a special form
      window.localStorage.setItem('agfMetaData', JSON.stringify(this.latestAgfMetaData))
      // and return the metadata for the current page 'staticstart' event
      return this.latestAgfMetaData
    },
    backForwardCacheHandler () {
      // use pageshow event to detect if the page was restored from the bfcache
      window.addEventListener('pageshow', (event) => {
        // if the page was restored from the bfcache
        if (event.persisted) {
          // get the latest metadata from the local storage
          this.latestAgfMetaData = JSON.parse(window.localStorage.getItem('agfMetaData'))
          // terminate the measurement of the previous page
          this.nSdkInstance.ggPM('staticend', this.latestAgfMetaData)
          // start the measurement of the new page loaded from bfcache
          this.nSdkInstance.ggPM('staticstart', this.agfMetaData())
        }
      })
    }
  }
}
</script>
