<template>
  <div :class="['bx-content__media', 'bx-typo--content-media', toImageSizeStyleClass(layout)]"
       :data-tc="`image-${layout?.size}`">
    <div :class="[getStyleClassForLayoutSize(layout)]">
      <figure :class="{ 'bx-content__media--portrait': isPortrait }">
        <picture>
          <source v-for="size in srcSet"
                  :key="size.width"
                  type="image/jpeg"
                  :media="`(max-width: ${size.width}px)`"
                  :srcset="size.src">
          <img :src="src"
               :loading="loading"
               :width="width"
               :height="height"
               :title="imageTitle"
               :alt="imageAltText">
        </picture>
        <figcaption v-if="imageCredit"
                    class="bx-content__media-credit bx-content__media-img-credit">
          Foto: {{ imageCredit }}
        </figcaption>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParagraphTypeTmdbImage',
  props: {
    image: {
      type: Object,
      required: true
    },
    loading: {
      type: String,
      default: 'lazy'
    }
  },
  computed: {
    layout () {
      return {
        size: 'l'
      }
    },
    isPortrait () {
      return this.height > this.width
    },
    src () {
      const baseUrl = 'https://image.tmdb.org/t/p'
      return `${baseUrl}/original${this.image.filePath}`
    },
    srcSet () {
      const baseUrl = 'https://image.tmdb.org/t/p'
      const filePath = this.image.filePath
      return [
        {
          src: `${baseUrl}/w300${filePath}`,
          width: 300
        },
        {
          src: `${baseUrl}/w780${filePath}`,
          width: 780
        },
        {
          src: `${baseUrl}/w1280${filePath}`,
          width: 1280
        },
        {
          src: `${baseUrl}/original${filePath}`,
          width: 1920
        }
      ]
    },
    width () {
      return this.image.width || 1920
    },
    height () {
      return this.image.height || 1080
    },
    imageTitle () {
      return 'TMDB Image'
    },
    imageAltText () {
      return 'TMDB Image'
    },
    imageCredit () {
      return 'TMDB'
    }
  },
  methods: {
    getStyleClassForLayoutSize (layout) {
      // convert page type name to css class name
      switch (layout?.size) {
        case 'm':
          return 'bx-content--floating'
        case 'xl':
          return ''
        case 'xxl':
          return ''
        case 'l':
        default:
          return ''
      }
    },
    toImageSizeStyleClass (layout) {
      // convert page type name to css class name
      return layout?.size?.length ? 'bx-content--image-' + layout.size : ''
    }
  }
}
</script>
